import React, { useEffect, useMemo, useState } from "react";
import {
	PageHeader,
	Spin,
	Row,
	Col,
	Button,
	Modal,
    List,
    Tag,
    Statistic,
    Card,
    Divider,
    Alert,
    Space,
    Empty
} from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined, FundTwoTone } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import day from "dayjs";

import './index.css';
import { PERIOD_TYPE, UNIX_TYPE } from "../../constant";
import { PeriodSelect, LogModal, SearchLogForm } from '../../components';
import { getProjectAsync } from "../../redux/actions/project";
import {
    getUserLogDataByPeriodAsync,
    addLogAsync,
    addNoPeriodLogAsync,
    updateLogAsync,
    deleteLogAsync,
    getNoPeriodLogByUserAsync,
    deleteNoPeriodLogAsync,
    updateNoPeriodLogAsync
} from "../../redux/actions/log";

const NONE_PERIOD_TYPE = PERIOD_TYPE['NONE'].value;
const LOAD_MORE = { quantity: 10, end: 10 };

export default withRouter(function Log(props) {
    const dispatch = useDispatch();
    const projectId = props.match.params.projectId;

    // Get data from redux
	const { activeUser } = useSelector(state => state.userReducer);
    const project = useSelector(state => state.projectReducer[projectId]);
    const { periodLogs, noPeriodLogs } = useSelector(state => state.logReducer);

    // Sets log modal
    const [logModalData, setLogModalData] = useState({});
    const [modalVisible, setModalVisible] = useState(false);

    // Sets period when the project has period
    const [datePeriod, setDatePeriod] = useState(null);
    const [periodType, setPeriodType] = useState(null);
    const [periodList, setPeriodList] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [isPeriodLocked, setIsPeriodLocked] = useState(false);

    const { userId } = activeUser;
	const [loading, setLoading] = useState(false);
	const [projectLoading, setProjectLoading] = useState(true);
    const [logList, setLogList] = useState([]);
    const [totalTime, setTotalTime] = useState(0);
    const [pagination, setPagination] = useState(LOAD_MORE);

    useEffect(() => {
		dispatch(getProjectAsync(projectId));
	}, [projectId]);

    useEffect(() => {
        if (!project) {
            return;
        }

        const { periodType } = project;

        if (periodType !== NONE_PERIOD_TYPE) {
            const { period } = project;
            const periodsKeys = period ? Object.keys(period).sort() : [];
            const activePeriods = [];
            let periods = [];
            let curPeriod = null;

            periodsKeys.forEach((key) => {
                const periodData = period[key];

                if (periodData.active !== "false") {
                    activePeriods.push(periodData);
                }

                periods.push(periodData);
            });

            curPeriod = activePeriods[activePeriods.length - 1];

            setPeriodList(periods);
            setSelectedPeriod(curPeriod);
        } else {
            const endDate = day(day().format('YYYY-MM-DD')).unix();
			const startDate = endDate * 1 - UNIX_TYPE['UNIX_DAY'] * 10;

			setDatePeriod({ startDate, endDate });
            dispatch(getNoPeriodLogByUserAsync(projectId, userId));
        }

        setPeriodType(periodType);
        setTimeout(() => {
            setProjectLoading(false);
        }, 200);
    }, [project]);

    useEffect(() => {
        if (periodType === NONE_PERIOD_TYPE) {
            return;
        }

        if (selectedPeriod) {
            if (selectedPeriod.isLocked === undefined || selectedPeriod.isLocked === false) {
                setIsPeriodLocked(false);
            } else {
                setIsPeriodLocked(true);
            }

            dispatch(getUserLogDataByPeriodAsync(projectId, userId, selectedPeriod.startDate));

            setTimeout(() => {
                setLoading(false);
            }, 300);
        } else {
            setLogList([]);
        }
    }, [selectedPeriod]);

    // Gets logs when the project has periods
    useEffect(() => {
        if (periodType === NONE_PERIOD_TYPE) {
            return;
        }

        let data = [];
        let totalTime = 0;

        if (periodLogs) {
            for (let k in periodLogs) {
                const log = periodLogs[k];

                if (!log.isDeleted) {
                    totalTime = (totalTime + log['logHours']) * 10000 / 10000;

                    data.push(log);
                }
            }

            totalTime = totalTime.toFixed(2) * 1;
        }

        data = data.sort((a, b) => parseInt(b.logDate) - parseInt(a.logDate));

        setLogList(data);
        setTotalTime(totalTime);
    }, [periodLogs]);

    // Gets logs when the project has no period
    useEffect(() => {
        if (periodType !== NONE_PERIOD_TYPE) {
            return;
        }

        if (!datePeriod) {
            return;
        }

        const { startDate, endDate } = datePeriod;
        let data = [];
        let totalTime = 0;

        if (noPeriodLogs) {
            for (let date in noPeriodLogs) {
                if (parseInt(date) >= startDate && parseInt(date) <= endDate) {
                    const logs = noPeriodLogs[date];

                    for (let logId in logs) {
                        const log = logs[logId];

                        if (!log.isDeleted) {
                            totalTime = (totalTime + log['logHours']) * 10000 / 10000;

                            data.push(log);
                        }
                    }
                }
            }

            totalTime = totalTime.toFixed(2) * 1;
        }

        data = data.sort((a, b) => parseInt(b.logDate) - parseInt(a.logDate));

        setLogList(data);
        setTotalTime(totalTime);
    }, [noPeriodLogs, datePeriod]);

	const onCreate = async (values, isCreateAnother) => {
        if (!isCreateAnother) {
            setModalVisible(false);
        }

        setLoading(true);

        const logId = day().unix();
        const logData = {
            ...values,
            isDeleted: false,
            userId,
            logId
        };

        if (hasPeriod()) {
            if (isPeriodLocked) {
                return;
            }

            const periodKey = selectedPeriod.startDate;

            await addLogAsync(projectId, periodKey, logData);
        } else {
            await addNoPeriodLogAsync(projectId, logData);
        }

        // For better user experience
        setTimeout(() => {
            setLoading(false);
        }, 100);
	};

    const onEdit = async (values) => {
        setModalVisible(false);

        if (isPeriodLocked) {
            return;
        }

        const { updateValue, extraValue } = values;

        if (hasPeriod()) {
            const periodKey = selectedPeriod.startDate;
            const logData = Object.assign({}, extraValue, updateValue);

            await updateLogAsync(projectId, periodKey, logData);
        } else {
            const logData = Object.assign({}, extraValue, updateValue);
            const extraLogDate = extraValue.logDate;
            const newLogDate = updateValue.logDate;

            if (extraLogDate !== newLogDate) {
                setLoading(true);

                await deleteNoPeriodLogAsync(projectId, extraValue);
                await updateNoPeriodLogAsync(projectId, logData);
                // For better user experience
                setTimeout(() => {
                    setLoading(false);
                }, 50);
            } else {
                await updateNoPeriodLogAsync(projectId, logData);
            }
        }
    }

    const onDelete = (log) => {
        if (isPeriodLocked) {
            return;
        }

        Modal.confirm({
            content: "Sure to delete the log record?",
            onOk: async () => {

                if (hasPeriod()) {
                    const periodKey = selectedPeriod.startDate;

                    deleteLogAsync(projectId, periodKey, log);
                } else {
                    deleteNoPeriodLogAsync(projectId, log)
                }
            }
        });
    };

    const onCancel = () => {
        setLogModalData({});
        setModalVisible(false);
    };

    const onSelectedPeriodChanged = (value) => {
        setLoading(true);
        setSelectedPeriod(value);
        setPagination(LOAD_MORE);
    };

    const handleEditBtnClick = (value) => {
        const LogModalData = Object.assign({}, {
            editMode: true,
            okText: 'Save',
            title: 'Edit Log',
            value,
            period: hasPeriod() ? selectedPeriod : null
        });

        setLogModalData(LogModalData);
        setModalVisible(true);
    };

    const handleLogBthClick = () => {
        if (hasPeriod() && selectedPeriod === undefined) {
           return;
        }

        const period = hasPeriod() ? selectedPeriod : null;
        const { startDate, endDate } = period || datePeriod;
        const currentDate = day().unix();
        const isBetween = currentDate > startDate && currentDate < endDate;

        const LogModalData = Object.assign({}, {
            editMode: false,
            okText: 'Create',
            title: 'Create Log',
            value: {
                logDate: isBetween ?  currentDate : startDate
            },
            period
        });

        setLogModalData(LogModalData);
        setModalVisible(true);
    };

    const onLoadMore = () => {
        const { quantity, end } = pagination;
        let counts = quantity + end;
        counts = counts > logList.length ? logList.length : counts;

        setPagination({
            quantity,
            end: counts
        });
    }

    const renderList = useMemo(() => {
        if (!logList.length) {
            return <Empty description="No log found under select period"></Empty>;
        }

        const { end } = pagination;
        const dataSource = logList.slice(0, end);
        const loadMore = end < logList.length ? (
            <div className="App-log__load-more">
                <Button onClick={onLoadMore} size="large" type="primary">Load More</Button>
            </div>
        ) : null;

        return (
            <List
                bordered
                dataSource={dataSource}
                loadMore={loadMore}
                renderItem={
                    (item, index) => (
                        <List.Item
                            key={index}
                            actions={[
                                <Button
                                    disabled={isPeriodLocked}
                                    onClick={() => onDelete(item)}
                                    shape="circle"
                                    type="danger" icon={<DeleteOutlined />}
                                >
                                </Button>,
                                <Button
                                    disabled={isPeriodLocked}
                                    onClick={() => handleEditBtnClick(item)}
                                    shape="circle"
                                    icon={<EditOutlined />}
                                >
                                </Button>
                            ]}
                        >
                            <Row justify="space-between">
                                <Col span={24} className="App-log_detail">
                                    <p>
                                        <label>Task: </label>
                                        <span>{item.remark}</span>
                                    </p>
                                    <p>
                                        <label>Time: </label>
                                        <span>{(item['logHours'] * 10000 / 10000).toFixed(2) * 1}h</span>
                                    </p>
                                    {
                                        item['logDate'] && (
                                            <p>
                                                <label>Date: </label>
                                                <span>{day(item['logDate'] * 1000).format('ll')}</span>
                                            </p>
                                        )
                                    }
                                    {
                                        item.option && (
                                            <p className="App-log__task-option-tag">
                                                { item.option.map(x => (
                                                    <Tag key={x} color="blue">{x}</Tag>
                                                ))}
                                            </p>
                                        )
                                    }
                                    {
                                        item.overtimeReason && (
                                            <p>
                                                <span>OT Reason: </span>
                                                <span>{item.overtimeReason}</span>
                                            </p>
                                        )
                                    }
                                </Col>
                            </Row>
                        </List.Item>
                    )
                }
            >
            </List>
        );
    }, [logList, pagination]);

    const onSearch = dates => {
		setDatePeriod(dates);
	};

    const onRangeChange = dates => {
        setDatePeriod(dates);
    };

	const setPageTitle = () => {
        if (project) {
            return `Project ${project.displayName} Log`;
        } else {
            return '';
        }
    };

    const hasPeriod = () => {
        return periodType === NONE_PERIOD_TYPE ? false : true;
    };

    const setPeriodBlock = () => {
        return (
            <Row justify="space-between" className="App-log_period-wrapper">
                <Col>
                    <p className="App-log__date-period">
                        Please select the date period
                    </p>
                    <Space>
                        {
                            hasPeriod() ? (
                                <PeriodSelect
                                    periodList={periodList}
                                    onSelectedPeriodChanged={onSelectedPeriodChanged}
                                >
                                </PeriodSelect>
                            ) : (
                                <SearchLogForm datePeriod={datePeriod} onSearch={onSearch} onRangeChange={onRangeChange}/>
                            )
                        }
                        <Button
                            disabled={isPeriodLocked || (hasPeriod() && !selectedPeriod)}
                            type="primary"
                            size="medium"
                            icon={<PlusOutlined />}
                            onClick={handleLogBthClick}
                        >
                            Add Log
                        </Button>
                    </Space>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Total Hours"
                            value={totalTime}
                            prefix={<FundTwoTone twoToneColor="#52c41a" />}
                            suffix="H"
                        />
                    </Card>
                </Col>
                {
                    hasPeriod() && isPeriodLocked && (
                        <Col span={24} style={{ margin: '10px 0' }}>
                            <Alert message="This period has been locked." type="warning" showIcon />
                        </Col>
                    )
                }
            </Row>
        );
    };

	return (
        <div className="App-log">
            {projectLoading ? <Spin className="App-log__project-loading" size="large"/> : (
                <>
                    <PageHeader title={setPageTitle()} />
                    <Divider/>
                    {setPeriodBlock()}
                    <Divider/>
                    <Row>
                        <Col span={24}>
                            {
                                loading ? <Spin className="App-log__list-loading" size="medium"/> : renderList
                            }
                        </Col>
                    </Row>
                </>
            )}
            <LogModal
                visible={modalVisible}
                data={logModalData}
                onCreate={onCreate}
                onEdit={onEdit}
                onCancel={onCancel}
            ></LogModal>
        </div>
	);
});
