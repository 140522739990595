import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PlusOutlined, SmileTwoTone, FrownTwoTone } from "@ant-design/icons";
import {
	Row,
	Col,
	Tabs,
	Button,
	notification,
	Pagination
} from "antd";
import dayjs from 'dayjs';

import "./index.css";
import { PERIOD_TYPE, PROJECT_TYPE } from "../../constant";
import { createProjectAsync, getProjectsAsync, updateProjectAsync } from "../../redux/actions/project";
import { CreateProjectFrom, ProjectItemCard } from "../../components";
import { ProjectProviderImplementation } from "../../service/projectService";

const { TabPane } = Tabs;
const NONE_PERIOD_TYPE = PERIOD_TYPE['NONE'].value;
const tabPaneList = {
	user: [PROJECT_TYPE.active],
	admin: [PROJECT_TYPE.active, PROJECT_TYPE.inactive]
};
const PAGE_SIZE = 12;

function Projects() {
	const dispatch = useDispatch();
	const history = useHistory();
	const { activeUser } = useSelector(state => state.userReducer);
	const { projects } = useSelector(state => state.projectReducer);
	const [projectType, setProjectType] = useState('active');
	const [modalVisible, setModalVisible] = useState(false);
	const [editProjectValue, setEditProjectValue] = useState({});
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(()=>{
		dispatch(getProjectsAsync());
	}, []);

	const Notify = {
		duration: 3,
	
		style: {
			borderRadius: '8px',
			position:'fixed',
			top: '20px',
			right: '20px',
			fontWeight: "bold",
			fontSize: '14px'
		},

		success: function (successText) {
			notification.open({
				style: this.style,
				duration: this.duration,
				icon: <SmileTwoTone />,
				message: <p style={{ fontSize: '18px', color: "#1890ff" }}>Success</p>,
				description: <span>{ successText }</span>
			});
		},

		error: function (errMsg) {
			notification.open({
				style: this.style,
				duration: this.duration,
				icon: <FrownTwoTone twoToneColor="red"/>,
				message: <p style={{ fontSize: '18px', color: 'red' }}>Error</p>,
				description: <span>{ errMsg }</span>
			});
		}
	};

	const onCreate = async (data) => {
        const { values } = data;
        const { periodType, displayName } = values;
        let projectEntity = Object.assign({}, values, {
			inactive: false,
            userId: activeUser.userId,
			members: {
                [activeUser.userId]: true
            }
		});

        if (periodType !== NONE_PERIOD_TYPE) {
            const { periodValue, startDate } = values;
			const endDate = dayjs.unix(startDate).add(periodValue, periodType).add(-1, 'day').unix();
            const currentUNIX = dayjs(dayjs().format('YYYY-MM-DD')).unix();
            const period = {
                [startDate]: { startDate, endDate, active: (currentUNIX >= startDate).toString()}
            }

            projectEntity = Object.assign(projectEntity, {
                period
            });
        }

		setModalVisible(false);

		const result = await createProjectAsync(projectEntity);

		if (result) {
			Notify.success(`${displayName} created`);
		} else {
			Notify.error('Create failed!');
		}
	};

	const onCancel = () => {
		setModalVisible(false);
	};

	const onEdit = async data => {
		const { projectId, values } = data;
		const project = Object.assign({}, projects[projectId], values);

		setModalVisible(false);

		const result = await updateProjectAsync(projectId, project);

		if (result) {
			Notify.success(`${project.displayName} edited`);
		} else {
			Notify.error('Edit failed!');
		}
	};

	const onProjectEditBtnClick = projectId => {
		setEditProjectValue({
			editMode: true,
			projectId,
			...projects[projectId]
		});
		setModalVisible(true);
	};

	const onCreateProjectBtnClick = () => {
		setEditProjectValue({
			editMode: false
		});
		setModalVisible(true);
	};

	const onProjectContentClick = (id) => {
		history.push(`/log/${id}`);
	};

	const CreateProjectButton = () => {
		return (
			activeUser.role.includes("admin") &&
			projectType === 'active' &&
			(
				<Button
					type="primary"
					className="App-projects__create-project"
					size="large"
					icon={<PlusOutlined />}
					onClick={onCreateProjectBtnClick}
				>
					Create Project
				</Button>
			)
		);
	};

	const onTabChange = (tabKey) => {
		setProjectType(tabKey);
		setCurrentPage(1);
	};

	const onPageChange = (e) => {
		setCurrentPage(e);
	};

	const projectList = useMemo(() => {
		if (!projects) {
			return [];
		}

		let _projectList = [];

 		for (let key in projects) {
			const project = projects[key];
			const { inactive, userId, members } = project;
			
			if (activeUser.role === 'user' && userId !== activeUser.userId && (!members || !members[activeUser.userId])) {
				continue;
			}

			const data = {
				projectId: key,
				...project
			};

			if (projectType === 'active' && !inactive) {
				_projectList.push(data);

				continue;
			}

			if (projectType === 'inactive' && inactive) {
				_projectList.push(data);

				continue;
			}
		}

		return _projectList.sort((a, b) => a.displayName.localeCompare(b.displayName));
	}, [projects, projectType]);

	const renderProjectList = useMemo(() => {
		if (!projectList.length) {
			return null;
		}

		const showPagination = projectList.length > PAGE_SIZE ? true : false;
		const _projectList = projectList.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);

		return (
			<>
				<Row gutter={[24, 48]}>
					{_projectList.map(project => (
						<Col
							span={8}
							key={project.projectId}
							style={{ minWidth: "300px", paddingRight: "20px" }}
						>
							<ProjectItemCard
								project={project}
								isAssign={project.members ? project.members[activeUser.userId] : false}
								onProjectEditBtnClick={onProjectEditBtnClick}
								role={activeUser.role}
								onProjectContentClick={onProjectContentClick}
							/>
						</Col>
					))}
				</Row>
				{
					showPagination && (
						<Row justify="end">
							<Pagination
								className="App-projects__pagination"
								current={currentPage}
								total={projectList.length}
								showSizeChanger={false}
								pageSize={PAGE_SIZE}
								onChange={onPageChange}></Pagination>
						</Row>
					)
				}
			</>
		);
	}, [projectList, currentPage]);

	return (
		<div className="App-projects">
			<Tabs
				className="App-projects__tabs"
				type="card"
				size="large"
				onChange={onTabChange}
				tabBarExtraContent={<CreateProjectButton/>}
			>
				{
					tabPaneList[activeUser.role].map(({ label, value }) => {
						return (
							<TabPane tab={label} key={value}>
								{ projectType === value && renderProjectList }
							</TabPane>
						);
					})
				}
			</Tabs>
			<CreateProjectFrom
				visible={modalVisible}
				onCancel={onCancel}
				onCreate={onCreate}
				onEdit={onEdit}
				value={editProjectValue}
			></CreateProjectFrom>
		</div>
	);
}

const ProjectWrapper = props =>
	<ProjectProviderImplementation>
		<Projects {...props}></Projects>
	</ProjectProviderImplementation>


export default ProjectWrapper;
