import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Radio, InputNumber, DatePicker } from 'antd';
import dayjs from 'dayjs';

import './index.css';
import { PERIOD_TYPE } from '../../constant'

const DEFAULT_PERIOD_TYPE = PERIOD_TYPE['NONE'].value;

export default function CreateProjectFrom({ visible, onCreate, onEdit, onCancel, value = {} }) {
    const [periodType, setPeriodType] = useState(DEFAULT_PERIOD_TYPE);
    const [showPeriod, setShowPeriod] = useState(false);
    const [form] = Form.useForm();
    const { projectId, editMode } = value;

    useEffect(()=>{
        const { displayName, periodType, periodValue, description, startDate }  = value;
        const _startDate = startDate && dayjs.unix(startDate) || '';
        const _periodTypeValue = periodType || DEFAULT_PERIOD_TYPE;
        
        setPeriodType(_periodTypeValue);
        setFieldsValue({
            displayName,
            periodValue,
            description,
            startDate: _startDate,
            periodType: _periodTypeValue
        });
    }, [value, form]);

    useEffect(() => {
        if (periodType === 'none') {
            setShowPeriod(false);
        } else {
            setShowPeriod(true);
        }
    }, [periodType]);

    const onOkClick = () => {
        const okCallback = editMode ? onEdit : onCreate;

        form.validateFields().then(fieldsValues => {
            const values = Object.assign({}, fieldsValues);

            values.startDate = dayjs(values.startDate.format("YYYY/MM/DD")).unix();

            const formData = {
                projectId,
                values
            };

            resetFields();
            okCallback(formData);
        }).catch(error => {
            console.log(error);
        });
    }

    const onCancelClick = () => {
        resetFields();
        onCancel();
    };

    const setFieldsValue = (data) => {
        form.setFieldsValue(data);
    };

    const resetFields = () => {
        form.resetFields();
        setFieldsValue({ periodType: DEFAULT_PERIOD_TYPE });
    };

    const onPeriodTypeChange = (e) => {
        setPeriodType(e.target.value);
    };

    const disablePeriodTypeRadio = (value) => {
        const noneValue = PERIOD_TYPE.NONE.value;
        const isNonePeriodType = periodType === noneValue;

        return editMode && (isNonePeriodType && value !== noneValue || !isNonePeriodType && value === noneValue);
    };

    return (
        <Modal
            destroyOnClose
            title={editMode ? "Update Project information" : "Create Project"}
            okText={editMode ? "Save" : "Create"}
            visible={visible}
            onOk={onOkClick}
            onCancel={onCancelClick}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_create_project"
            >
                <Form.Item
                    name="displayName"
                    label="Project Name"
                    rules={[{ required: true, message: 'Please input project name!' }]}
                >
                    <Input maxLength={50}/>
                </Form.Item>
                <Form.Item
                    name="periodType"
                    label="Period Type"
                >
                    <Radio.Group onChange={onPeriodTypeChange}>
                        {
                            Object.entries(PERIOD_TYPE).map(([key, obj]) => {
                                return (
                                    <Radio
                                        key={key}
                                        value={obj.value}
                                        disabled={disablePeriodTypeRadio(obj.value)}
                                    >
                                        {obj.label}
                                    </Radio>
                                );
                            })
                        }
                    </Radio.Group>
                </Form.Item>
                { showPeriod &&
                    (
                        <Form.Item
                            name="periodValue"
                            label="Period value"
                            rules={[{ required: true, message: 'Please input project period value!' }]}
                        >
                            <InputNumber min={1} max={10} className="App-project-form-period"/>
                        </Form.Item>
                    )
                }
                <Form.Item
                    name="startDate"
                    label="Start date"
                    rules={[{ required: true, message: 'Please enter start date!' }]}
                >
                    <DatePicker format="YYYY/MM/DD" disabled={editMode}/>
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Project Description"
                    rules={[{ required: true, message: 'Please input project description!' }]}
                >
                    <Input.TextArea rows={4} />
                </Form.Item>
            </Form>
        </Modal>
    );
}
