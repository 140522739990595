import React, { useEffect, useState, useMemo } from "react";
import {
	Collapse,
	Statistic,
	Row,
	Col,
	Card,
	Form,
	List,
	Empty,
	Button,
	Spin,
	Modal,
    Tag,
	Divider,
	Space,
	Select,
	Dropdown,
	Menu,
	Tooltip,
	Checkbox,
	Typography,
} from "antd";
import {
	FundTwoTone,
	DownloadOutlined,
	LockOutlined,
	UnlockOutlined,
	FundOutlined,
	DownOutlined,
	EditOutlined,
	FilePdfOutlined,
	FileExcelOutlined
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import dayjs from "dayjs";
import { Parser } from 'json2csv';
import FileSaver from 'file-saver';

import "./index.css";
import { getUsersAsync } from "../../redux/actions/user";
import { getProjectAsync, updatePeriodAsync} from "../../redux/actions/project";
import {
	getLogDataAsync,
	getNoPeriodLogDataAsync,
	deleteLogData,
	updateLogAsync,
	deleteNoPeriodLogAsync,
	updateNoPeriodLogAsync
} from "../../redux/actions/log";
import { PeriodSelect, SearchLogForm, LogModal } from "../../components";
import { PERIOD_TYPE, STATIC_MAP, UNIX_TYPE } from "../../constant";

const { Option } = Select;
const { Panel } = Collapse;
const { Text } = Typography
const NONE_PERIOD_TYPE = PERIOD_TYPE['NONE'].value;

const ReportDetail = (props) => {
	const dispatch = useDispatch();
	const projectId = props.match.params.projectId;
	const [loading, setLoading] = useState(true);

	// Gets data from reducer
	const { [projectId]: project } = useSelector(state => state.projectReducer);
	const { logData } = useSelector(state => state.logReducer);
	const { users } = useSelector(state => state.userReducer);

	// Sets states
	const [periodList, setPeriodList] = useState([]);
	const [currentPeriod, setCurrentPeriod] = useState(null);
	const [periodType, setPeriodType] = useState(null);
	const [datePeriod, setDatePeriod] = useState(null);
	const [historyLog, setHistoryLog] = useState(null);
	const [activePanelKeys, setActivePanelKeys] = useState([]);
	const [overtimeFilterOn, setOverFilterOn] = useState(false);

	// Edits report log
	const [logModalData, setLogModalData] = useState({});
	const [modalVisible, setModalVisible] = useState(false);
	const [downloadModalVisible, setDownloadModalVisible] = useState(false);
	const handleEditBtnClick = (value) => {
		const LogModalData = Object.assign({}, {
            editMode: true,
            okText: 'Save',
            title: 'Edit Log',
            value,
			period: currentPeriod
        });

        setLogModalData(LogModalData);
        setModalVisible(true);
	};
	const onEditLog = async (values) => {
		setModalVisible(false);
		setLoading(true);

		const { updateValue, extraValue } = values;

		if (hasPeriod()) {
            const periodKey = currentPeriod.startDate;
            const logData = Object.assign({}, extraValue, updateValue);

            await updateLogAsync(projectId, periodKey, logData);
			dispatch(getLogDataAsync(projectId));
        } else {
            const logData = Object.assign({}, extraValue, updateValue);
            const extraLogDate = extraValue.logDate;
            const newLogDate = updateValue.logDate;

            if (extraLogDate !== newLogDate) {
                await deleteNoPeriodLogAsync(projectId, extraValue);
                await updateNoPeriodLogAsync(projectId, logData);
            } else {
                await updateNoPeriodLogAsync(projectId, logData);
            }

			dispatch(getNoPeriodLogDataAsync(projectId));
        }

		setLogModalData({});
		// For better user experience
		setTimeout(() => {
			setLoading(false);
		}, 50);
	};
	const onCancelEditLog = () => {
		setLogModalData({});
        setModalVisible(false);
	};

	useEffect(() => {
		dispatch(getUsersAsync());
		dispatch(getProjectAsync(projectId));

		return () => {
			dispatch(deleteLogData());
		};
	}, []);

	useEffect(() => {
		if (!project) {
			return;
		}

		const { periodType } = project;

		setPeriodType(periodType);

		if (periodType !== NONE_PERIOD_TYPE) {
			const { period } = project;
			const periodsKeys = period ? Object.keys(period).sort() : [];
			const periods = [];
			const activePeriods = [];

            periodsKeys.forEach(key => {
                const periodData = period[key];

				if (periodData.active !== "false") {
					activePeriods.push(periodData);
				}

                periods.push(periodData);
            });

			const lastPeriod = activePeriods[activePeriods.length - 1] || null;

			setCurrentPeriod(lastPeriod);
			setPeriodList(periods);
			dispatch(getLogDataAsync(projectId));
		} else {
			const endDate = dayjs(dayjs().format('YYYY-MM-DD')).unix();
			const startDate = endDate * 1 - UNIX_TYPE['UNIX_DAY'] * 10;

			setDatePeriod({ startDate, endDate });
			dispatch(getNoPeriodLogDataAsync(projectId));
		}
	}, [project]);

	useEffect(() => {
		if (!logData) {
			return;
		}

		if (!Object.keys(logData).length) {
			setHistoryLog(null);
			setTimeout(() => {
				setLoading(false);
			}, 50);

			return;
		}

		let activeLogData = null;

		if (periodType === NONE_PERIOD_TYPE) {
			if (!datePeriod) {
				return;
			}

			activeLogData = getDatePeriodLogData(logData, datePeriod);
		} else {
			if (!currentPeriod) {
				return;
			}

			activeLogData = getPeriodLogData(logData, currentPeriod);
		}

		setHistoryLog(activeLogData);
		setTimeout(() => {
			setLoading(false);
		}, 50);
	}, [logData, currentPeriod, datePeriod]);

	const hasPeriod = () => {
		return periodType && (periodType !== NONE_PERIOD_TYPE);
	};

	const getPeriodLogData = (logData, period) => {
		const { startDate } = period;
		let activeLogData = {};

		console.log(logData, 'logData')

		for (let user in logData) {
			const userLogs = logData[user];
			const periodLogs = userLogs[startDate];
			let data = [];
			let hours = 0;

			for (let key in periodLogs) {
				const log = periodLogs[key];

				if (!log.isDeleted) {
					const { logHours } = log;

					hours = hours + logHours;

					data.push(log)
				}
			}

			data = data.sort((a, b) => parseInt(b.logDate) - parseInt(a.logDate));

			if (hours > 0) {
				activeLogData[user] = {
					hours,
					logs: data
				};
			}
		}

		if (Object.keys(activeLogData).length) {
			return activeLogData;
		}

		return null;
	};

	const getDatePeriodLogData = (logData, datePeriod) => {
		const { startDate, endDate } = datePeriod;
		let activeLogData = {}

		for (let user in logData) {
			let data = [];
			const userLogs = logData[user];
			let hours = 0;

			for (let date in userLogs) {
				if (parseInt(date) >= startDate && parseInt(date) <= endDate) {
					const dateLogs = userLogs[date];

					for (let logId in dateLogs) {
						const log = dateLogs[logId];

						if (!log.isDeleted) {
							const { logHours } = log;

							hours = hours + logHours;

							data.push(log);
						}
					}
				}
			}

			data = data.sort((a, b) => parseInt(b.logDate) - parseInt(a.logDate));

			if (hours > 0) {
				activeLogData[user] = {
					hours,
					logs: data
				};
			}
		}

		if (Object.keys(activeLogData).length) {
			return activeLogData;
		}

		return null;
	};

	const computerStatistic = data => {
		let reportUsers = 0;
		let totalHours = 0;
		let averageHours = 0;

		for (let user in data) {
			console.log(users, 'users')
			const currentUser = users[user];
			console.log(currentUser, 'currentUser');

			if (currentUser?.inactive === true) {
				continue;
			}

			const { hours } = data[user];

			if (hours) {
				reportUsers++;
				totalHours = totalHours + hours;
			}
		}

		if (reportUsers === 0 || totalHours === 0) {
			averageHours = 0;
		} else {
			averageHours = totalHours / reportUsers;
		}

		return {
			reportUsers,
			totalHours: totalHours.toFixed(2) * 1,
			averageHours: averageHours.toFixed(2) * 1
		};
	};

	const setPeriodTitle = period => {
		return `${dayjs.unix(period.startDate).format('ll')} - ${dayjs.unix(period.endDate).format('ll')}`;
	};

	const onLockStatusChange = () => {
		Modal.confirm({
			content: (
				<div>
					Are you sure you want to{" "}
					{!currentPeriod.isLocked ? "Lock" : "Unlock"} the current
					period?
				</div>
			),
			onOk: async () => {
				let periodLocked = false;
				const { isLocked, startDate } = currentPeriod;

				if (isLocked === undefined || isLocked === false) {
					periodLocked = true;
				}

				const result = await updatePeriodAsync(projectId, startDate, periodLocked);

				if (result) {
					const newPeriod = Object.assign({}, currentPeriod, {isLocked: periodLocked});
					const newPeriodList = [];

					periodList.forEach(period => {
						if (startDate === period.startDate) {
							newPeriodList.push(newPeriod);
						} else {
							newPeriodList.push(period);
						}
					});

					setCurrentPeriod(newPeriod);
					setPeriodList(newPeriodList);
				}
			}
		});
	};

	const onSelectedPeriodChanged = period => {
		setCurrentPeriod(period);
		setActivePanelKeys([]);
	};

	const periodStatistics = useMemo(() => {
		if (!periodList.length || !logData) {
			return null;
		};

		const activePeriods = periodList.filter(x => x.active !== 'false').sort((x, y) => x.startDate - y.startDate);

		if (!activePeriods.length) {
			return;
		}

		const lastPeriod = activePeriods[activePeriods.length - 1];
		const secondPeriod = activePeriods[activePeriods.length - 2];
		const statistics = [];

		if (lastPeriod) {
			// console.log(first)

			const data = getPeriodLogData(logData, lastPeriod);
			const latestStatistic = computerStatistic(data);

			statistics.push({
				...latestStatistic,
				title: setPeriodTitle(lastPeriod)
			});
		}

		if (secondPeriod) {
			const data = getPeriodLogData(logData, secondPeriod);
			const secondStatistic = computerStatistic(data);

			statistics.push({
				...secondStatistic,
				title: setPeriodTitle(secondPeriod)
			});
		}

		return statistics.map((statistic, index) => {
			return (
				<div key={index}>
					<Row className="App-reports__statistic-title">{statistic.title}</Row>
					<Row gutter={16} className="App-reports__statistic-item">
						{Object.keys(STATIC_MAP).map(key => (
							<Col key={key} span={8}>
								<Card>
									<Statistic
										title={STATIC_MAP[key].label}
										value={statistic[key]}
										prefix={<FundTwoTone twoToneColor="#52c41a" />}
										suffix={STATIC_MAP[key].suffix}
									/>
								</Card>
							</Col>
						))}
					</Row>
				</div>
			)
		});
	}, [logData, periodList]);

	const detailStatics = useMemo(() => {
		if (!datePeriod) {
			return null;
		}

		const statistic = computerStatistic(historyLog);
		const title = setPeriodTitle(datePeriod);

		return (
			<>
				<Row className="App-reports__statistic-title">{title}</Row>
				<Row gutter={16} className="App-reports__statistic-item">
					{Object.keys(STATIC_MAP).map(key => (
						<Col key={key} span={8}>
							<Card>
								<Statistic
									title={STATIC_MAP[key].label}
									value={statistic[key]}
									prefix={<FundTwoTone twoToneColor="#52c41a" />}
									suffix={STATIC_MAP[key].suffix}
								/>
							</Card>
						</Col>
					))}
				</Row>
			</>
		);
	}, [datePeriod, historyLog])

	const onCollapseChange = (key) => {
		setActivePanelKeys(key);
	};

	const getSortedUserIds = (ids) => {
		let filterUsers = [];

		ids.forEach(id => {
			if (!users[id].inactive) {
				filterUsers.push({
					id,
					displayName: users[id].displayName
				});
			}
		});

		filterUsers = filterUsers.sort((a, b) => a.displayName.localeCompare(b.displayName))


		return filterUsers.map(x => x.id);
	};

	const renderLogList = useMemo(() => {
		if (!historyLog) {
			return <Empty className="App-reports__no-data" />;
		}

		const userIdList = getSortedUserIds(Object.keys(historyLog));

		console.log(userIdList, 'userIdList')


		return (
			<Collapse
				activeKey={activePanelKeys}
				className="App-reports__collapse"
				style={{ width: "100%", fontWeight: "bold" }}
				onChange={onCollapseChange}
			>
				{
					userIdList.map(userId => {
						let userLogData = historyLog[userId];

						if (!userLogData) {
							return null;
						}

						if (overtimeFilterOn) {
							const { logs } = userLogData;
							const overTimeLog = logs.filter(x=> {
								if (x.option) {
									return x.option.includes('overtime');
								}

								return false
							});

							const totalHour = overTimeLog.reduce((acc, cur) => acc + cur.logHours, 0);

							userLogData = {
								hours: totalHour,
								logs: overTimeLog
							}
						}

						let userLogs = userLogData.logs;

						if (userLogs && userLogs.length) {
							userLogs = userLogs.sort((a, b) => parseInt(b.logDate) - parseInt(a.logDate));

							const userName = users[userId].displayName;
							const totalHour = userLogData.hours;
							const header = (
								<Row justify="space-between" align="middle">
									<Col span={14}>
										<span className="App-reports__period-header">{userName}</span>
									</Col>
									<Col span={2} style={{textAlign: 'right'}}>
										<span className="App-reports__period-header">{totalHour.toFixed(2) * 1}H</span>
									</Col>
									<Col span={2} style={{textAlign: 'center'}}>
										<span className="App-reports__period-header">Edit</span>
									</Col>
								</Row>
							);

							return (
								<Panel header={header} key={userId}>
									<List
										itemLayout="horizontal"
										dataSource={userLogs}
										renderItem={item => (
											<List.Item key={item.logId} className="App-reports__detail-item">
												<Row justify="space-between">
													<Col span={14}>
														<p>
															<label className="App-reports__detail-item-label">Task:</label>
															<span>{item.remark}</span>
														</p>
														{
															item.logDate && (
																<p>
																	<label className="App-reports__detail-item-label">Date:</label>
																	<span>{dayjs(item.logDate * 1000).format('ll')}</span>
																</p>
															)
														}
														{
															item.option && (
																<p className="App-reports__task-option-tag">
																	{item.option.map(x => (
																		<Tag key={x} color="blue">{x}</Tag>
																	))}
																</p>
															)
														}
														{
															item.overtimeReason && (
																<p>
																	<label className="App-reports__detail-item-label">OT Reason:</label>
																	<span>{item.overtimeReason}</span>
																</p>
															)
														}
													</Col>
													<Col span={2} style={{textAlign: 'right'}}>
														<span className="App-reports__row-hour">{item.logHours.toFixed(2) * 1}H</span>
													</Col>
													<Col span={2} style={{textAlign: "center"}}>
														<Tooltip key="edit" title="Edit" onClick={() => handleEditBtnClick(item)}>
															<EditOutlined style={{fontSize: "20px"}}/>
														</Tooltip>
													</Col>
												</Row>
											</List.Item>
										)}
									/>
								</Panel>
							);
						}
					})
				}
			</Collapse>
		);
	}, [historyLog, activePanelKeys, users, overtimeFilterOn]);

	const onSearch = dates => {
		setDatePeriod(dates);
	};

	const onExpandClick = () => {
		if (activePanelKeys.length) {
			setActivePanelKeys([]);
		} else {
			setActivePanelKeys(Object.keys(historyLog));
		}
	};

	const onOvertimeFilterChange = (e) => {
		const isChecked = e.target.checked;

		setOverFilterOn(isChecked)
	}

	// #region Downloads the reports
	const onExportPdf = (periodLogList) => {
		if (!periodLogList || !periodLogList.length) {
			return;
		}

		periodLogList.forEach(({ period, activeLogs, statics }) => {
			const doc = new jsPDF();
			let rowIndex = 20;

			doc.autoTable({
				head: [
					[
						{
							content: `${project.displayName} Reports:`,
							styles: { halign: 'left', textColor: [9, 109, 217] }
						}
					]
				],
				styles: { fontSize: 24, margin: { right: 10 } },
				body: [[{
					content: setPeriodTitle(period),
					styles: { halign: 'left', cellWidth: 200, fontSize: 16 },
				}]],
				theme: 'plain'
			});

			doc.autoTable({
				startY: doc.lastAutoTable.finalY + 10,
				head: [
					Object.keys(STATIC_MAP).map(key => {
						return {
							content: STATIC_MAP[key].label,
							styles: { halign: 'left' }
						}
					})
				],
				styles: { fontSize: 18, margin: { right: 10 } },
				body: [
					Object.keys(STATIC_MAP).map(key => {
						return {
							content: `${statics[key]} ${STATIC_MAP[key].suffix}`,
							styles: { halign: 'left' }
						}
					})
				],
				theme: 'plain'
			});

			const userIdList = getSortedUserIds(Object.keys(activeLogs));

			if (userIdList.length) {
				rowIndex = 70;
				doc.setFontSize(22);

				rowIndex = 80;

				// Loop user
				userIdList.forEach(userId => {
					const { logs, hours } = activeLogs[userId];
					const logCounts = logs.length;

					if (logCounts) {
						const userName = users[userId].displayName;
						const body = [];

						logs.forEach((logItem, logIndex) =>{
							const { remark, logHours, logDate } = logItem;

							if (logItem.option && logItem.overtimeReason) {
								body.push([
									{
										rowSpan: 4,
										content: logIndex + 1,
										styles: { halign: 'left', cellWidth: 10 },
									},
									{
										content: `Task: ${remark}`,
										styles: { halign: 'left', cellWidth: 120 },
									},
									{
										rowSpan: 4,
										content: logHours.toFixed(2) * 1 + 'H ',
										styles: { halign: 'right', fontStyle: 'bold', cellWidth: 60 },
									}
								]);
							} else if ((logItem.option && !logItem.overtimeReason) || (!logItem.option && logItem.overtimeReason)) {
								body.push([
									{
										rowSpan: 3,
										content: logIndex + 1,
										styles: { halign: 'left', cellWidth: 10},
									},
									{
										content: `Task: ${remark}`,
										styles: { halign: 'left', cellWidth: 120 },
									},
									{
										rowSpan: 3,
										content: logHours.toFixed(2) * 1 + 'H ',
										styles: { halign: 'right', fontStyle: 'bold', cellWidth: 60 },
									}
								]);
							} else {
								body.push([
									{
										rowSpan: 2,
										content: logIndex + 1,
										styles: { halign: 'left', cellWidth: 10 },
									},
									{
										content: `Task: ${remark}`,
										styles: { halign: 'left', cellWidth: 120 },
									},
									{
										rowSpan: 2,
										content: logHours.toFixed(2) * 1 + 'H ',
										styles: { halign: 'right', fontStyle: 'bold', cellWidth: 60 },
									}
								]);
							}

							if (logDate) {
								body.push([{
									content: `Date: ${dayjs(logDate * 1000).format('ll')}`
								}]);
							}

							if (logItem.option) {
								body.push([{
									content: logItem.option && logItem.option.join('  '),
									styles: {
										textColor: [9, 109, 217]
									}
								}]);
							}

							if (logItem.overtimeReason) {
								body.push([{
									content: `OT Reason: ${logItem.overtimeReason}`
								}]);
							}

							body.push([{
								content: '',
								colSpan: 3
							}]);
						});

						doc.autoTable({
							startY: doc.lastAutoTable.finalY + 10,
							showHead: 'firstPage',
							head: [
								[
									{
										content: `${userName}`,
										colSpan: 2,
										styles: { halign: 'left', fillColor: [9, 109, 217], textColor: [255, 255, 255] },
									},
									{
										content: `Total hours: ${hours.toFixed(2) * 1} H `,
										colSpan: 1,
										styles: { halign: 'right', fillColor: [9, 109, 217], textColor: [255, 255, 255]},
									}
								]
							],
							styles: { fontSize: 12, margin: { right: 10 } },
							body,
							theme: 'plain'
						});

						rowIndex = rowIndex + (logCounts) * 20 + 10;
					}
				})
			}

			doc.save(setPeriodTitle(period));
		});
	};

	const onExportCsv = (periodLogList) => {
		if (!periodLogList || !periodLogList.length) {
			return;
		}

		const titleCSV = new Parser().parse({ 'Project Name': `${project.displayName} Reports` });
		const fields = [
			{ label: ' ', value: 'name' },
			{ label: 'Work Classification', value: 'remark' },
			{ label: 'Log hours', value: 'logHours' },
			{ label: 'Log date', value: 'date' },
			{ label: 'Option', value: 'option' },
			{ label: 'Overtime Reason', value: 'overtimeReason' },
			{ label: 'Total', value: 'userTotalHours'}
		];

		periodLogList.forEach(({ period, activeLogs, statics }) => {
			const periodCSV = new Parser().parse({ '': setPeriodTitle(period) });
			const staticData = {};
			const staticFields = [];

			Object.keys(STATIC_MAP).forEach(key => {
				staticData[key] = `${statics[key]} ${STATIC_MAP[key].suffix}`;
				staticFields.push({
					label: STATIC_MAP[key].label,
					value: key
				});
			});

			const staticCSV = new Parser({ fields: staticFields }).parse(staticData);
			const data = [];
			const userIdList = getSortedUserIds(Object.keys(activeLogs));

			userIdList.forEach((userId, index) => {
				const { logs, hours } = activeLogs[userId];
				const logCounts = logs.length;

				if (logCounts) {
					const userName = users[userId].displayName;

					data.push({ name: userName, userTotalHours: `${hours}` });
					logs.forEach(logItem => {
						const { logDate, remark, logHours, option, overtimeReason } = logItem;

						data.push({
							remark,
							overtimeReason,
							option: option?.toString(),
							logHours: `${logHours.toFixed(2) * 1}`,
							date: logDate ? dayjs.unix(logDate).format('ll') : ''
						});
					});

					if (index !== userIdList.length - 1) {
						data.push({'name': ' ' });
					}
				}
			});

			const json2csvParser = new Parser({ fields });
			const csv = json2csvParser.parse(data);
			const blob = new Blob([`${titleCSV}\n${periodCSV}\n\n${staticCSV}\n\n${csv}`], { type: 'text/plain;charset=utf-8;' });

			FileSaver.saveAs(blob, `${project.displayName}${setPeriodTitle(period)}.csv`);
		});
	};

	const ExportWay = {
		PDF: onExportPdf,
		CSV: onExportCsv
	};

	const formatDownloadData = (periodList = [], filterLogs) => {
		const periodLogList = [];

		periodList.forEach(period => {
			const activeLogs = filterLogs(logData, period) || [];
			const statics = computerStatistic(activeLogs);
			const logItem = { period, activeLogs, statics };

			periodLogList.push(logItem);
		});

		return periodLogList;
	};

	const onDateExportPdf = () => {
		onExportPdf(formatDownloadData([datePeriod], getDatePeriodLogData));
	};

	const onDateExportCsv = () => {
		onExportCsv(formatDownloadData([datePeriod], getDatePeriodLogData));
	};

	const [form] = Form.useForm();
	const setDownloadAction = () => {
		const initialValues = {
			fileType: Object.keys(ExportWay)[0],
			periods: [periodList[0]]
		};
		const onChangeFileType = (value) => {
			form.setFieldsValue({fileType: value});
		};
		const onChangeDownloadPeriod = (value) => {
			form.setFieldsValue({periods: value});
		};
		const closeDownloadModal = () => {
			setDownloadModalVisible(false);
			form.resetFields();
		};
		const onDownloadReport = () => {
			form.validateFields().then(({periods, fileType}) => {
				const pdfWay = Object.keys(ExportWay)[0];
				const onDownload = ExportWay[fileType];
				const setStatistic = fileType === pdfWay ? computerStatistic : null;

				onDownload(formatDownloadData(periods, getPeriodLogData, setStatistic));
				closeDownloadModal();
			})
		};

		return hasPeriod() ?
		(
			<>
				<Button
					type="primary"
					size="large"
					icon={<DownloadOutlined/>}
					onClick={() => setDownloadModalVisible(true)}
				>
					Download reports
				</Button>
				<Modal
					className="App-reports__download-modal"
					title="Download Reports"
					okText="Download"
					visible={downloadModalVisible}
					onCancel={closeDownloadModal}
					onOk={onDownloadReport}
				>
					<Form
						name="downloadReportForm"
						form={form}
						labelCol={{ span: 6 }}
						initialValues={initialValues}
					>
						<Form.Item label="Periods" name="periods" rules={[{required: true, message: "Please select period!"}]}>
							<PeriodSelect periodList={periodList} mode="multiple" onSelectedPeriodChanged={onChangeDownloadPeriod}/>
						</Form.Item>
						<Form.Item label="File Type" name="fileType" rules={[{required: true, message: "Please select file type!"}]}>
							<Select style={{width: "300px"}} onChange={onChangeFileType}>
								{
									Object.keys(ExportWay).map(key => {
										return (
											<Option key={key} value={key}>{key}</Option>
										);
									})
								}
							</Select>
						</Form.Item>
					</Form>
				</Modal>
			</>
		)
		:
		(
			<Dropdown
				disabled={!historyLog}
				trigger={['click']}
				overlay={
					<Menu>
						<Menu.Item key="1" onClick={onDateExportPdf}>
							<span><FilePdfOutlined /> PDF</span>
						</Menu.Item>
						<Menu.Item key="2" onClick={onDateExportCsv}>
							<span><FileExcelOutlined/> CSV</span>
						</Menu.Item>
					</Menu>
				}
			>
				<Button
					type="primary"
					size="large"
					icon={<DownloadOutlined/>}
				>
					Download reports <DownOutlined/>
				</Button>
			</Dropdown>
		)
	};
	// #endregion

	return loading ? (
		<Spin className="App-reports__loading" size="large" />
	) : (
		<div className="App-reports__wrapper">
			<p className="App-reports__title">
				<FundOutlined style={{ color: "var(--ts-blue)", marginRight: '4px' }}/>
				<span>{project && project.displayName}: Latest Reports</span>
			</p>
			<Divider/>
			{hasPeriod() ? periodStatistics : detailStatics}
			<Divider/>
			<Row className="App-reports__date-period">
				Please select the date period
			</Row>
			<Row align="top" style={{ padding: '10px 0 20px 0' }}>
				<Col flex="auto" >
					{
						hasPeriod() ? (
							<PeriodSelect
								periodList={periodList}
								defaultValue={currentPeriod}
								onSelectedPeriodChanged={onSelectedPeriodChanged}
							/>
						) : (
							<SearchLogForm datePeriod={datePeriod} onSearch={onSearch}/>
						)
					}
				</Col>
				<Col >
					<Space>
						{
							hasPeriod() && currentPeriod && (
								<Button
									onClick={onLockStatusChange}
									type="primary"
									size="large"
									icon={currentPeriod.isLocked ? <LockOutlined /> :<UnlockOutlined />}
									danger={currentPeriod.isLocked}
								>
									{currentPeriod.isLocked ?  "UnLock reports" : "Lock reports"}
								</Button>
							)
						}
						{ setDownloadAction() }
					</Space>
				</Col>
			</Row>
			<Row gutter={10} align="middle">
				<Col>
					<Button
						disabled={!historyLog}
						ghost
						type="primary"
						onClick={onExpandClick}
						style={{width: "120px", marginBottom: "4px"}}
					>
						{ activePanelKeys.length ? "Collapse All" : "Expand All" }
					</Button>
				</Col>
				<Col>
					<Checkbox
						checked={overtimeFilterOn}
						onChange={onOvertimeFilterChange} disabled={!historyLog}
					>
						<Text>Overtime</Text>
					</Checkbox>
				</Col>
			</Row>
			{renderLogList}
			<LogModal
				visible={modalVisible}
				data={logModalData}
				onEdit={onEditLog}
				onCancel={onCancelEditLog}
			></LogModal>
		</div>
	);
};

export default withRouter(ReportDetail);
